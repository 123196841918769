import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { HttpClient } from "@sentry/integrations"
import { errorHandler } from './bootstrap'
import { isAppMode } from '../base/appFlags'

export const sentryErrorTracking = async (app, router) => {
  // ending the standalone session before starting the main one
  window.removeEventListener('error', errorHandler)
  Sentry.getCurrentHub().endSession()

  Sentry.init({
    app,
    beforeSend(event) {
      // Add a tag for PWA if the app is running as a PWA
      if (isAppMode()) {
        event.tags = event.tags || {};
        event.tags.pwa = 'true';
      }
      return event;
    },
    dsn: 'https://8c06331cbfe04f649595da140d8ffb1b@o447795.ingest.sentry.io/5879345',
    ignoreErrors: [
      'NetworkError',
      'Network Error',
      'Non-Error promise rejection captured',
      'timeout',
      'AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22$/',
      'Failed to fetch',
      'Load failed'
    ],
    allowUrls: [
      'https://cdn2-courses.iphonephotographyschool.com',
      'https://pwa-courses.iphonephotographyschool.com',
      'https://courses.iphonephotographyschool.com'
    ],
    integrations: [
      new Integrations.BrowserTracing({
        ...(router && { outingInstrumentation: Sentry.vueRouterInstrumentation(router) }),
        tracingOrigins: ['pwa-courses.iphonephotographyschool.com', 'courses.iphonephotographyschool.com', 'courses.photographypro.com']
      }),
      new HttpClient()
    ],
    sendDefaultPii: true,
    environment: window.App.appEnvironment,
    hooks: ['activate', 'create', 'mount', 'unmount'],
    logErrors: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0
  })
}
